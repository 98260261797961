.profile-info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  margin: 50px auto; 
  max-width: 75%;
  font-size: 35px;
}

.left-side {
  flex: 1;
}

.photo-container {
  display: flex;
  justify-content: center;
}

.rounded-photo {
  border-radius: 50%;
  overflow: hidden; /* Ensure the image remains within the rounded container */
}

.keywords {
  margin-top: 10px; /* Adjust margin as needed */
}

.horizontal-line {
  margin-top: 10px;
  border: 1;
  border-top: 2px solid #a1bacf;
  width: 53.4%;
}

.buttons a {
  display: inline-block;
  width: 100px; /* Set a fixed width */
  margin-right: 30px;
  margin-top: 10px;
  overflow: hidden; /* Hide any overflow */
}


@media screen and (max-width: 500px) {
  .profile-info {
    flex-direction: column; /* Change flex-direction for smaller screens */
    align-items: center; /* Align items to the center for smaller screens */
    max-width: 90%; /* Adjust maximum width for smaller screens */
  }

  .left-side {
    max-width: 90%; /* Adjust maximum width for smaller screens */
    margin-right: 10px; /* Add margin from the right side on smaller screens */
    text-align: center; /* Align text to the center for smaller screens */
  }
}

.social-logo {
  width: 50%; /* Set the desired width for the social media logos */
  height: auto; /* Maintain aspect ratio */
  margin-right: 10px;
  cursor: pointer;
  transition: all 0.2s ease;
}
.social-logo:hover {
  width: 56px; /* Increased size when clicked */
}