.contact-container {
  width: 100%; /* Set width to 100% */
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  text-align: center; /* Center-align the content */
}

.contact-container h1 {
  font-size: 54px;
  margin-bottom: 5px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-options {
  font-size: 26px; /* Adjust font size */
  margin-top: 10px;
  border: 1;
  border-top: 2px solid #a1bacf;
}

.linkedin-button {
  margin-top: 10px; /* Adjust the margin as needed */
  padding: 10px 20px;
  background-color: #3adb19;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.linkedin-button:hover {
  background-color: #15b122;
}

.linkedin-button span {
  font-size: 26px; /* Adjust font size */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

.contact-text {
  margin-bottom: 10px; /* Adjust as needed */
}

/* Hide picture on smaller screens */
@media screen and (max-width: 768px) {
  .linkedin-logo {
    display: none;
  }
}
