.audio-player {
    width: 100%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .audio-player h3 {
    font-size: 24px;
    margin-bottom: 10px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  }
  
  .audio-player canvas {
    width: 100%;
    height: 100px;
    background-color: #df3b3b;
  }
  
  .audio-player audio {
    width: 100%;
    color: #df3b3b;
  }
  
  /* Adjustments for smaller screens */
  @media screen and (max-width: 768px) {
    .audio-player {
      width: 80%;
    }
  }
  
  