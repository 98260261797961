.App {
  text-align: center;
  font-size: 25px;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

body {
  background-color: #e7eff0;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.highlighted:hover {
  color: rgb(11, 13, 114);
}


.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family:sans-serif;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.navigation {
  margin-bottom: 10px;
  font-size: 20px;
}
.terminal-prompt {
  display: flex;
  padding-left: 1px;
  align-items: center;
  margin-bottom: 10px;
  justify-content: flex-start;
  color: whitesmoke;
}

.terminal-prompt input[type="text"] {
  font-family: "Menlo", monospace;
  font-size: 16px;
  color: #fff;
  background-color: #2d2d2d;
  border: 1px solid #343434;
  border-radius: 4px;
  padding: 5px;
  margin-right: 5px;
  outline: none;
  width: 100%;
}

.terminal-output {
  width: 100%;
  height: calc(100% - 60px);
  padding: 10px;
  overflow-y: scroll;
  font-family: "Menlo", monospace;
  font-size: 16px;
  color: #fff;
}
.NoteInstructions {
  background-color: rgb(243, 140, 6);
  padding: 2px;
  text-align: center;
}
@media (max-width: 768px) {
  .terminal {
    height: 80vh;
    max-height: none;
  }
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.terminal-body {
  position: relative;
}

.cat-icon {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100px;
  height: 100px;
  background-image: url("https://media.tenor.com/wDzr_DRyv8oAAAAC/omg-cat.gif");
  background-size: contain;
  background-repeat: no-repeat;
  animation: run 3s linear infinite;
}

@keyframes run {
  0% {
    transform: translateX(0) translateY(0) rotate(0);
  }
  25% {
    transform: translateX(70%) translateY(0) rotate(90deg);
  }
  50% {
    transform: translateX(70%) translateY(70%) rotate(180deg);
  }
  75% {
    transform: translateX(0) translateY(70%) rotate(270deg);
  }
  100% {
    transform: translateX(0) translateY(0) rotate(360deg);
  }
}


header nav {
  display: flex;
  justify-content: center; /* Align items to the right */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

}

header nav ul {
  list-style: none;
  display: flex; /* Display items in a row */
}

header nav ul li {
  margin-left: 30px; /* Add space between list items */
}

header nav ul li:first-child {
  margin-left: 0; /* No margin for the first list item */
}

header nav ul li a {
  color: #222020;
  text-decoration: dotted;
}
.construction {
  font-size: 15px;
  color: rgb(207, 54, 15);
  text-align: center;
}
.tpmSummary{
  color: rgb(13, 19, 14);
  font-style: italic;
  font-weight: bold;
}