/* Container for all projects */
.projects-container {
  display: flex;
  justify-content: center; /* Centers the cards */
  flex-wrap: wrap;
  gap: 180px; /* Adjusted gap to reduce spacing between cards */
  padding: 20px;
}

/* Each project card */
.project {
  background-color: #edf3eb;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(7, 235, 22, 0.747);
  padding: 10px;
  flex: 1 1 20%; /* Adjusted width to make cards flexible */
  max-width: 300px; /* Ensures cards do not exceed 300px */
  transition: transform 0.3s;
  cursor: pointer;
}

.project:hover {
  transform: translateY(-3px);
  background-color: #7eff33;
}

/* Image styling inside the card */
.project img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

/* Project title */
.project h3 {
  font-size: 1em;
  margin-top: 8px;
  margin-bottom: 5px;
}

/* Short description under the title */
.project-description {
  font-size: 0.8em;
  color: #3b3232;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 5px;
}
.project-skills {
  font-size: 0.8em;
  padding: 10px 0;
  text-align: left;
  color: #272727;
}

.project-skills ul {
  list-style-type: disc;  /* Use a disc as the bullet point */
  padding-left: 20px;     /* Indentation for bullet points */
  margin: 0;              /* Remove any default margin */
}

.project-skills li {
  font-size: 14px;        /* Adjust font size if necessary */
  color: #333;            /* Set a dark color for the text */
  margin-bottom: 5px;     /* Add space between list items */

}

.project-skills li:hover{
  color: #bb76ff;
}

/* Popup for selected project */
.project-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(1, 15, 8, 0.315); /* Dark overlay */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.popup-content {
  background-color: #c6e7b3;
  padding: 20px;
  border: 1px solid red;
  border-radius: 10px;
  max-width: 80%; /* Now takes 80% of the screen width */
  width: 50%; /* Ensures the popup is wider */
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: relative;
  overflow-y: scroll;
  z-index: 1000;
  max-height: 90%; /* Ensures it doesn't exceed screen height */
  overflow-y: auto; /* Allows scrolling if content overflows */
  
}

.popup-content img {
  width: 50%; /* Make image take full width of popup */
  height: auto;
  border-radius: 8px;
  margin-bottom: 10px;
}

/* Close button for popup */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: #ff5b5b;
  border: none;
  color: white;
  font-size: 16px;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1001;;
}

.close-btn:hover {
  background-color: #ff3333;
}

/* Heading in popup */
.popup-content h2 {
  font-size: 1.5em;
  margin-bottom: 15px;
  color: rgb(160, 90, 90);
}

/* Description in popup */
.popup-content p {
  width: 50%; /* Match the text width to the image width */
  margin: 0 auto; /* Center the text */
  text-align: justify; /* Optional: If you want the text to be justified */
  font-size: 1em;
  line-height: 1.4;
  color: #353535;
}

/* Adjustments for smaller screens */
@media screen and (max-width: 768px) {
  .popup-content {
    width: 90%; /* Popup takes most of the screen on mobile */
    max-width: 90%;
  }
}
/* Media Query: For screens 2000px and above */
@media screen and (min-width: 2000px) {
  .project {
    width: calc(33.33% - 20px); /* Adjust width to fit 3 cards evenly */
  }
}

/* Media Query: For tablets and smaller screens (max-width 1024px) */
@media screen and (max-width: 1024px) {
  .projects-container {
    justify-content: space-around; /* Adjust container */
  }

  .project {
    width: calc(45% - 20px); /* Two cards in a row */
  }
}

/* Media Query: For mobile screens (max-width 768px) */
@media screen and (max-width: 768px) {
  .projects-container {
    flex-direction: column; /* Stack cards vertically on mobile */
    align-items: center; /* Center the cards */
  }

  .project {
    width: 90%; /* Full width card on mobile */
    max-width: none;
    margin-bottom: 20px; /* Space between stacked cards */
  }
}
