.professional-summary {
  width: 43%; /* Default width for larger screens */
  margin: 5vh auto; /* Center the container horizontally and vertically 1/4 from the top */
  border-radius: 10px; /* Add border-radius for rounded corners */
  color: rgb(3, 3, 3);
}

.professional-summary-box {
  text-align: left; /* Align the text to the left */
  font-size: 25px; /* Default font size */
  line-height: 1.5; /* Set the line height for better readability */
  word-wrap: break-word; /* Wrap long words if necessary */
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  overflow-wrap: break-word; /* Wrap long words if necessary for browsers that don't support word-wrap */
  max-width: 100%; /* Ensure paragraph fits within its container */
}

/* Add media query for smaller screens */
@media screen and (max-width: 768px) {
  .professional-summary {
    width: 100%; /* Adjust width to 100% for smaller screens */
  }

  .professional-summary-box {
    max-width: 100%; /* Adjust maximum width for smaller screens */
    font-size: 18px; /* Adjust font size for smaller screens */
    text-align: center; /* Align the text to the center on smaller screens */
  }
}
