body {
  margin: 0;
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  background-color: bisque;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;}
